.amcompany-customer-account .page-main {
    .actions-toolbar {
        @include am-flex(space-between, center, wrap);

        padding: $amcompany__indent 0;
    }

    .actions-toolbar > .primary,
    .actions-toolbar > .secondary {
        padding: $amcompany__indent / 2;
        display: inline-block;
    }

    .amcompany-form-create > .amcompany-toolbar {
        @include am-flex(flex-end);

        padding: $amcompany__indent__l 0;
    }

    .amcompany-fieldset {
        margin: 0 0 22px 0;
    }
}

.amasty_company-profile-index {
    .amcompany-title {
        display: inline-block;
        padding: 0 $amcompany__indent 0 0;
    }

    .page-title-wrapper {
        display: none;
    }
}

//
//  Mobile
//  --------------------------------------------

@include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
    .amcompany-customer-account .page-main {
        .actions-toolbar > .primary,
        .actions-toolbar > .secondary {
            width: auto;
        }
    }
}
