/* amasty company custom variables */
$amcompany__indent: 10px;
$amcompany__indent__m: $amcompany__indent + $amcompany__indent / 2;
$amcompany__indent__l: $amcompany__indent * 2;
$amcompany__indent__xl: $amcompany__indent__l + $amcompany__indent / 2;

$amcompany-tree__line__color: map-get($theme-colors, 'light');

$amcompany__color__primary: map-get($theme-colors, 'primary');
$amcompany__hover__color__primary: map-get($theme-colors, 'primary');
$amcompany__focus__color__primary: map-get($theme-colors, 'primary');
$amcompany__color__error: map-get($theme-colors, 'negative');
$amcompany__color__text: map-get($theme-colors, 'dark');


%amcompany-tree-line-vertical {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 1px dashed #cfcfcf;
    content: '';
}
%amcompany-tree-line-horizontal {
    position: absolute;
    right: 0;
    left: -18px;
    width: $amcompany__indent__m;
    border-top: 1px dashed #cfcfcf;
    content: '';
}

%am-word-break {
    word-wrap: break-word;
    word-break: break-word;

    .ie11 & {
        word-break: break-all;
    }
}

@mixin am-flex(  $horiz-pos: none, $vert-pos: none, $wrap: none) {
    display: flex;

    @if ($horiz-pos != none) {
        justify-content: $horiz-pos;
    }

    @if ($vert-pos != none) {
        align-items: $vert-pos;
    }

    @if ($wrap != none) {
        flex-wrap: $wrap;
    }
}

@mixin transition( $del: .2s) {
    @if ($del != .2s) {
        transition: all $del ease-in;
    }
}

@mixin am-mage-icon($ico: none) {
    display: inline-block;
    content: $ico;
    font-family: $icons__font-name;
}
