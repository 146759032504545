/* apply general table styles in order for it to be generally applied everywhere */

body.account {
    .table-wrapper {
        width: 100%;
    }

    .table {
        width: 100%;

        .col.actions {
            text-align: center;
            @include respond-to-up($order-table-breakpoint) {
                width: 332px;
                text-align: right;
            }
        }

        th {
            @include respond-to-down($order-table-breakpoint) {
                display: none;
            }
        }

        td,
        th {
            font-size: 14px;
        }

        tbody {
            @include respond-to-down($order-table-breakpoint) {
                text-align: center;
            }
        }

        tbody tr {
            @include respond-to-down($order-table-breakpoint) {
                display: inline-block;
                max-width: none;
                width: 100%;
                margin-top: spacers(2);
                padding: spacers(3);
                background: $white;

                &:last-child {
                    margin-bottom: spacers(2);
                }
            }
        }

        tfoot td,
        tbody td {
            color: $text-muted;

            @include respond-to-up($tweakpoint-nav-toggle) {
                padding: .5rem;
            }

            @include respond-to-down($order-table-breakpoint) {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                max-width: 100%;
                justify-content: space-between;
            }

            &:before {
                @include respond-to-down($order-table-breakpoint) {
                    content: attr(data-th);
                    display: inline;
                    padding-right: spacers(2);
                    font-weight: bold;
                }
            }

            &.id {
                @include respond-to-up($order-table-breakpoint) {
                    padding-left: spacers(2);
                }
            }

            &.actions {
                &:before {
                    display: none;
                }
            }

            .action {
                @extend %button-base;
                @extend %button-whisper;
                display: inline-block;
                width: auto;
                box-sizing: border-box;
                font-size: 14px;

                @include respond-to-down($order-table-breakpoint) {
                    padding-left: 0;
                    padding-right: 0;
                    box-sizing: border-box;
                }

                &.activate {
                    @extend %button-base;
                    @extend %button-whisper;
                }
            }
        }

        tfoot td {
            @include respond-to-down($order-table-breakpoint) {
                padding: spacers(3);
            }
        }

        th.actions {
            opacity: 0;
        }

        tbody > tr {
            @include hover-focus {
                box-shadow: $shadow-panel;

                td {
                    color: $body-color;

                    @include respond-to-up($order-table-breakpoint) {
                        background: $white;
                    }
                }
            }
        }

        .table-caption {
            display: none;
        }

        .item.options,
        .item-options {
            @include respond-to-down($order-table-breakpoint) {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-end;
                width: 100%;
            }

            dd,
            dt {
                @include respond-to-down($order-table-breakpoint) {
                    margin: 0;
                }
            }

            dd {
                @include respond-to-down($order-table-breakpoint) {
                    margin-left: spacers(2);
                }
            }

            dd + dt {
                @include respond-to-down($order-table-breakpoint) {
                    margin-left: spacers(3);
                }
            }
        }
    }

    .table:not(.table-order-items) {
        th,
        td {
            // padding: spacers(3);
        }
    }
}
