/* override company account specific styling */
body.amcompany-customer-account {
    .amcompany-form-create input {
        width: 100%;
    }
    .column.main > .amcompany-title {
        padding: 1rem 0;
        letter-spacing: 0;
        font-weight: 400;
        text-align: center;
        font-size: 24px;
        text-transform: uppercase;
        line-height: 1;
        margin-top: 0;
        margin-bottom: .5rem;

        @include respond-to-up($tweakpoint-nav-toggle) {
            font-size: 40px;
            margin-bottom: 40px;
        }
    }
}

.amcompany-tree-block > .amcompany-toolbar {
    padding: $amcompany__indent__m 0 $amcompany__indent__l;
    display: flex;

    .amcompany-button:not(:last-child) {
        margin: 0 $amcompany__indent__m 0 0;
        padding: 0 $amcompany__indent__m 0 0;
        border-right: 1px solid #a6a6a6;
        border-radius: 0;
    }
}
